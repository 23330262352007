var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1 tabela-anatomias-fisiologias",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":20,"page":_vm.page,"locale":"pt-BR","loading-text":"Carregando","no-data-text":"Nenhum dado encontrado","loading":_vm.loading,"server-items-length":_vm.total,"footer-props":{
    'items-per-page-text': 'Itens por página:',
    'items-per-page-options': [20, 50, 100],
    'show-first-last-page': true,
    'show-current-page': true,
    'page-text': _vm.pageText,
  }},on:{"update:options":_vm.handleFilter},scopedSlots:_vm._u([{key:`body.prepend`,fn:function(){return [_c('tr',[_vm._l((_vm.headers),function(header,index){return [_c('th',{key:index},[_vm._t(header.value)],2)]})],2)]},proxy:true},{key:`item.id`,fn:function({ item }){return [(_vm.checkPermission(['anatomyPhysiology.edit', 'anatomyPhysiology.delete']))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","depressed":"","color":"fmq_gray","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Opções "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',[(_vm.checkPermission('anatomyPhysiology.edit'))?_c('v-list-item',{on:{"click":function($event){return _vm.gotTo(item.id)}}},[_c('v-list-item-title',[_vm._v("Editar")])],1):_vm._e(),(_vm.checkPermission('anatomyPhysiology.delete'))?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('excluir', item)}}},[_c('v-list-item-title',[_vm._v("Excluir")])],1):_vm._e()],1)],1):_vm._e()]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }